// 404.jsx
import React from 'react';

const MaintenancePage = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>404 Error</h1>
      <p>The website you are looking for cannot be found</p>
    </div>
  );
};

export default MaintenancePage;
